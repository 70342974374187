import React, { useState, useEffect } from "react";
import { fallbackLanguage } from "../constants/fallbackLanguage";

const currentLocale = process.env.REACT_APP_PUBLIC_LOCALE || fallbackLanguage;

const retryImport = async (importFn, maxRetries = 3, delay = 1000) => {
  for (let i = 0; i < maxRetries; i++) {
    try {
      return await importFn();
    } catch (error) {
      if (i === maxRetries - 1) {
        throw error;
      }

      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }
};

export const LocalizedImage = ({ src, className }) => {
  const [image, setImage] = useState({ src: null, error: false });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadImage = async () => {
      setIsLoading(true);
      try {
        const importFn = () => import(`../assets/images/${currentLocale}/${src}`);
        const imageModule = await retryImport(importFn);

        setImage({ src: imageModule.default, error: false });
      } catch (error) {
        console.error("Failed to load localized image:", error);

        try {
          const fallbackImportFn = () => import(`../assets/images/en/${src}`);
          const fallbackModule = await retryImport(fallbackImportFn);

          setImage({ src: fallbackModule.default, error: true });
        } catch (fallbackError) {
          console.error("Failed to load fallback image:", fallbackError);

          setImage({ src: null, error: true });
        }
      } finally {
        setIsLoading(false);
      }
    };

    loadImage();
  }, [src]);

  const skeleton = (
    <>
      <style>
        {`
          @keyframes skeleton-pulse {
            0% { opacity: 0.1 }
            50% { opacity: 0.3 }
            100% { opacity: 0.1 }
          }
  
          .image-skeleton {
            min-width: 20px;
            border-radius: 8px;
          }
        `}
      </style>

      <img
        className={`${className} image-skeleton`}
        style={{
          backgroundColor: "var(--blue-light-5)",
          animation: "skeleton-pulse 1.5s ease-in-out 0.5s infinite",
        }}
      />
    </>
  );

  if (isLoading) {
    return skeleton;
  }

  if (image.src === null) {
    console.log("image.src === null");

    return skeleton;
  }

  return (
    <img
      src={image.src}
      alt={image.error ? `fallback ${src}` : src}
      className={className}
      onError={() => setImage((prev) => ({ ...prev, error: true }))}
    />
  );
};
