export const OrderedList = ({ list, className }) => (
  <ol className={className}>
    {list.map((item, index) => (
      <li
        key={index}
        className="body-small mt-4"
        dangerouslySetInnerHTML={{ __html: item }}
      />
    ))}
  </ol>
);
