import { forwardRef, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import en from "../assets/flags/en.svg";
import de from "../assets/flags/de.svg";
import uk from "../assets/flags/uk.svg";

import { paths } from "../constants/paths";
import { useTranslation } from "../hooks/useTranslation";

const flag = { en, uk, de };

export const LanguageButton = forwardRef(({ className = "" }, ref) => {
  const { currentLocale } = useTranslation();
  const { pathname } = useLocation();

  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    if (process.env.REACT_APP_LEGAL === "uk") {
      setLanguages([{ title: "uk", to: paths.uk }, { title: "en", to: paths.en }]);
    } else {
      setLanguages([{ title: "de", to: paths.de }, { title: "en", to: paths.en }]);
    }
  }, []);

  return (
    <div className={`language-image-container ${className}`} ref={ref}>
      {languages.map((item) => (
        <Link key={item.title} to={`${item.to}${pathname}`}>
          <img
            src={flag[item.title]}
            alt={`${item.title} flag`}
            className={`language-image ${item.title === currentLocale ? "active" : ""}`}
          />
        </Link>
      ))}
    </div>
  );
});
