export const TextArea = ({
  value,
  type,
  label,
  name,
  placeholder,
  required,
  containerClassName,
  onChange,
  error,
}) => {
  return (
    <div className={containerClassName}>
      {label && (
        <label
          htmlFor={name}
          className={`body-small weight-500 ${
            error ? "text-red" : "text-secondary"
          }`}
        >
          {label}
          {required && "*"}
        </label>
      )}

      <textarea
        value={value}
        required={required}
        type={type}
        id={name}
        name={name}
        rows={5}
        className={`input mt-8 ${error ? "err" : ""}`}
        placeholder={placeholder}
        onChange={(e) => onChange(name, e.target.value)}
      />

      {error && <p className="body-extra-small text-red mt-4">{error}</p>}
    </div>
  );
};
