import { Text } from "./form/Text";
import { TextArea } from "./form/Textarea";
import { useTranslation } from "../hooks/useTranslation";

export const FormStartup = ({ formData, onChange }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="modal-form">
        <Text
          required
          type="text"
          name="companyName"
          label={t.companyNameLabel}
          placeholder={t.companyNamePlaceholder}
          onChange={onChange}
          value={formData.companyName.value}
          error={formData.companyName.error}
        />
        <Text
          required
          type="email"
          name="emailStartup"
          label={t.emailLabel}
          placeholder={t.emailPlaceholder}
          onChange={onChange}
          value={formData.emailStartup.value}
          error={formData.emailStartup.error}
        />
        <Text
          required
          type="text"
          name="yearOfEstablishment"
          label={t.yearOfEstablishmentLabel}
          placeholder={t.yearOfEstablishmentPlaceholder}
          onChange={onChange}
          value={formData.yearOfEstablishment.value}
          error={formData.yearOfEstablishment.error}
        />
        <Text
          required
          type="text"
          name="countryAndCityOfRegistration"
          label={t.countryAndCityOfRegistrationLabel}
          placeholder={t.countryAndCityOfRegistrationPlaceholder}
          onChange={onChange}
          value={formData.countryAndCityOfRegistration.value}
          error={formData.countryAndCityOfRegistration.error}
        />
      </div>
      <TextArea
        required
        type="textarea"
        name="startupDescription"
        label={t.startupDescriptionLabel}
        placeholder={t.startupDescriptionPlaceholder}
        containerClassName="mt-12"
        onChange={onChange}
        value={formData.startupDescription.value}
        error={formData.startupDescription.error}
      />
    </>
  );
};
