const loginEn = "https://marmend.com/users/log_in";
const loginOther = "https://marmend.eu/users/log_in";
const tryForFreeEn = "https://marmend.com/users/register";
const tryForFreeOther = "https://marmend.eu/users/register";

export const paths = {
  home: "/",
  prices: "/prices",
  contacts: "/contact",
  aboutUs: "/about-us",
  career: "https://app.marmend.eu/jobs",
  login: {
    en: loginEn,
    de: loginOther,
    eu: loginOther,
    uk: loginOther,
  },
  tryForFree: {
    en: tryForFreeEn,
    de: tryForFreeOther,
    eu: tryForFreeOther,
    uk: tryForFreeOther,
  },
  vacancies: "/products/vacancies",
  atsDatabase: "/products/ats-database",
  hrPlatform: "/products/hr-platform",
  recruitingAsService: "/recruiting-as-service",
  notFound: "*",

  terms: "/legal/terms-of-service",
  privacy: "/legal/privacy-policy",
  plausibleIO: "https://plausible.io/privacy-focused-web-analytics",

  de: "https://marmend.de",
  eu: "https://about.marmend.eu",
  en: "https://about.marmend.com",
  uk: "https://marmend.com.ua",

  facebook: "#fb",
  xing: "#xing",
  linkedin: "https://www.linkedin.com/company/marmend/",
  instagram: "#i",
  x: "#x",
};
