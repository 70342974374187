export const UnorderedList = ({ list, className }) => (
  <ul className={`ul-terms-disc ${className}`}>
    {list.map((item, index) => (
      <li
        key={index}
        className="body-small mt-4"
        dangerouslySetInnerHTML={{ __html: item }}
      />
    ))}
  </ul>
);
