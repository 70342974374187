import { useCallback, useEffect, useMemo, useState } from "react";

import { ReactComponent as Cursor } from "../assets/cursor.svg";
import { ReactComponent as Puzzle } from "../assets/puzzle.svg";
import { ReactComponent as Magnet } from "../assets/magnet-1.4.svg";
import { ReactComponent as Vacation } from "../assets/vacation.svg";
import { ReactComponent as Megaphone } from "../assets/megaphone.svg";
import { ReactComponent as ArrowPath } from "../assets/arrow-path.svg";
import { ReactComponent as PencilAlt } from "../assets/pencil-alt.svg";
import { ReactComponent as PartyHorn } from "../assets/party-horn.svg";
import { ReactComponent as FaceFrown } from "../assets/face-frown.svg";
import { ReactComponent as CalendarDays } from "../assets/calendar-days.svg";
import { ReactComponent as RectangleStack } from "../assets/rectangle-stack.svg";
import { ReactComponent as ChatBubbleLeftRight } from "../assets/chat-bubble-left-right.svg";

import { useTranslation } from "../hooks/useTranslation";
import { useBackgroundChanger } from "../hooks/useBackgroundChanger";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { Ticker } from "./Ticker";

const CardBother = ({ icon, text, isActive = false }) => (
  <div className={`card-bother ${isActive ? "active" : ""}`}>
    <div className="icon-container">{icon}</div>
    <p className="body-small text-center">{text}</p>
  </div>
);

const Slideshow = () => {
  const { t } = useTranslation();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const slides = [
    { id: 1, content: t.manager },
    { id: 2, content: t.hr },
    { id: 3, content: t.recruiter },
  ];

  const rotateSlides = useCallback(() => {
    setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
  }, [slides.length]);

  useEffect(() => {
    const slideInterval = setInterval(rotateSlides, 2000);

    return () => clearInterval(slideInterval);
  }, [rotateSlides]);

  return (
    <div className="card-bother-textscroll">
      <div className="custom-rounded-item bl">
        <div className="visibility-hidden body-medium weight-500">
          {slides[0].content}
        </div>
        {slides.map((slide, index) => (
          <p
            key={slide.id}
            className={`slides body-medium weight-500 ${
              index === currentSlideIndex
                ? "slide-in"
                : index ===
                  (currentSlideIndex - 1 + slides.length) % slides.length
                ? "slide-out-up"
                : "slide-out-down"
            }`}
          >
            {slide.content}
          </p>
        ))}
      </div>
    </div>
  );
};

export const Bother = () => {
  const { t } = useTranslation();

  const questions1 = useMemo(
    () => [
      { icon: <Magnet />, text: t.question1 },
      { icon: <Megaphone />, text: t.question2 },
      { icon: <Puzzle />, text: t.question3 },
      { icon: <ArrowPath />, text: t.question4 },
      { icon: <PencilAlt />, text: t.question5 },
      { icon: <RectangleStack />, text: t.question6 },
    ],
    [t]
  );
  const questions2 = useMemo(
    () => [
      { icon: <PartyHorn />, text: t.question7 },
      { icon: <FaceFrown />, text: t.question8 },
      { icon: <CalendarDays />, text: t.question9 },
      { icon: <ChatBubbleLeftRight />, text: t.question10 },
      { icon: <Cursor />, text: t.question11 },
      { icon: <Vacation />, text: t.question12 },
    ],
    [t]
  );

  const activeIndices = useBackgroundChanger(questions1.length);
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <>
      <div className="card-bother-container">
        <Slideshow />

        {isMobile ? (
          <Ticker title="bother-1" direction="right">
            {questions1.map((item, index) => (
              <CardBother
                key={`q1-mobile-${index}`}
                icon={item.icon}
                text={item.text}
              />
            ))}
          </Ticker>
        ) : (
          <div className="card-bother-list list-1">
            {questions1.map((item, index) => (
              <CardBother
                key={`q1-${index}`}
                icon={item.icon}
                text={item.text}
                isActive={index === activeIndices.list1}
              />
            ))}
          </div>
        )}
      </div>

      <div className="section-bother-pulse relative">
        <div className="rounded-item mx-42">
          <div className="text-tertiary body-medium weight-500 whitespace-nowrap">
            {t.whatBothers}
          </div>
        </div>
        <div className="absolute absolute-center">
          <div className="pulse-container">
            <div className="pulse"></div>
            <div className="pulse"></div>
            <div className="pulse"></div>
            <div className="pulse"></div>
            <div className="pulse"></div>
          </div>
        </div>
      </div>

      <div className="card-bother-container">
        <div className="custom-rounded-item yel">
          <div className="body-medium weight-500 text-secondary">
            {t.employee}
          </div>
        </div>

        {isMobile ? (
          <Ticker title="bother-2" direction="right">
            {questions2.map((item, index) => (
              <CardBother
                key={`q2-mobile-${index}`}
                icon={item.icon}
                text={item.text}
              />
            ))}
          </Ticker>
        ) : (
          <div className="card-bother-list list-2">
            {questions2.map((item, index) => (
              <CardBother
                key={`q2-${index}`}
                icon={item.icon}
                text={item.text}
                isActive={index === activeIndices.list2}
              />
            ))}
          </div>
        )}
      </div>

      <div className="blur-ball blur-size-460 blur-bg-3 blur-filter-112-5 absolute-center"></div>
    </>
  );
};
