import { paths } from "../constants/paths";
import { fallbackLanguage } from "../constants/fallbackLanguage";

export const legal = (path) =>
  `${paths[process.env.REACT_APP_TERMS || fallbackLanguage]}${path}`;

export const login = `${
  paths.login[process.env.REACT_APP_LEGAL || fallbackLanguage]
}`;

export const tryForFree = (type) => {
  const tryForFreePath = `${
    paths.tryForFree[process.env.REACT_APP_LEGAL || fallbackLanguage]
  }`;

  if (type) {
    return tryForFreePath + `?type=${type}`;
  }

  return tryForFreePath;
};
