import { Link } from "react-router-dom";

import footerGradient from "../assets/footer-gradient.svg";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as SocialX } from "../assets/social-x.svg";
import { ReactComponent as SocialXing } from "../assets/social-xing.svg";
import { ReactComponent as SocialFacebook } from "../assets/social-facebook.svg";
import { ReactComponent as SocialLinkedin } from "../assets/social-linkedin.svg";
import { ReactComponent as SocialInstagram } from "../assets/social-instagram.svg";

import { paths } from "../constants/paths";
import { useTranslation } from "../hooks/useTranslation";

const year = new Date().getFullYear();

export const Footer = ({ toggleCookie }) => {
  const { t } = useTranslation();

  const handleShowCookie = () => {
    toggleCookie(true);
    document.body.style.overflow = "hidden";
  };

  return (
    <footer className="footer section">
      <div className="footer-content">
        <div className="footer-left-side">
          <Link to={paths.home} className="flex">
            <Logo className="footer-logo" />
          </Link>

          <p className="footer-description body-medium text-tertiary">
            {t.footerText}
          </p>

          <div className="footer-socials text-primary">
            <Link to={paths.facebook} target="_blank" className="footer-social">
              <SocialFacebook className="icon-32 fill-social" />
            </Link>
            <Link to={paths.xing} target="_blank" className="footer-social">
              <SocialXing className="icon-32 fill-social" />
            </Link>
            <Link to={paths.linkedin} target="_blank" className="footer-social">
              <SocialLinkedin className="icon-32 fill-social" />
            </Link>
            <Link
              to={paths.instagram}
              target="_blank"
              className="footer-social"
            >
              <SocialInstagram className="icon-32 fill-social" />
            </Link>
            <Link to={paths.x} target="_blank" className="footer-social">
              <SocialX className="icon-32 fill-social" />
            </Link>
          </div>
        </div>
        <div className="footer-right-side">
          <div className="flex flex-col">
            <p className="body-medium text-secondary">{t.company}</p>
            <div className="footer-links">
              <Link to={paths.aboutUs} className="body-small text-tertiary a">
                {t.aboutUs}
              </Link>
              <Link to={paths.career} className="body-small text-tertiary a">
                {t.career}
              </Link>
              <Link to={paths.prices} className="body-small text-tertiary a">
                {t.price}
              </Link>
              <Link to={paths.contacts} className="body-small text-tertiary a">
                {t.contacts}
              </Link>
            </div>
          </div>

          <div className="flex flex-col">
            <p className="body-medium text-secondary">{t.product}</p>
            <div className="footer-links">
              <Link to={paths.vacancies} className="body-small text-tertiary a">
                {t.vacancies}
              </Link>
              <Link
                to={paths.atsDatabase}
                className="body-small text-tertiary a"
              >
                {t.atsDatabase}
              </Link>
              <Link
                to={paths.hrPlatform}
                className="body-small text-tertiary a"
              >
                {t.hrPlatform}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <span className="text-tertiary body-small">© {year} Marmend, Inc.</span>
        <div className="footer-terms">
          <Link to={paths.terms} className="body-small text-tertiary a">
            {t.termsOfService}
          </Link>
          <Link to={paths.privacy} className="body-small text-tertiary a">
            {t.privacyPolicy}
          </Link>
          <Link
            onClick={handleShowCookie}
            className="body-small text-tertiary a"
          >
            Cookies
          </Link>
        </div>
      </div>

      <img
        src={footerGradient}
        alt="footer gradient"
        className="footer-gradient"
      />
    </footer>
  );
};
