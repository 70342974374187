import { useEffect, useMemo, useRef, useState } from "react";

import googleMeets from "../assets/google-meets.svg";
import slackIcon from "../assets/slack.svg";
import linkedIn from "../assets/linked-in.svg";
import { useTranslation } from "../hooks/useTranslation";
import { useMediaQuery } from "../hooks/useMediaQuery";

const interectionConfig = {
  intersected: [
    "translate(-20px, 20px) rotate(-5deg)",
    "translate(-260px, 15px) rotate(-10deg)",
    "translate(0px, -20px) rotate(5deg)",
  ],
  unintersected: [
    "translate(-20px, 20px)",
    "translate(-260px, 15px)",
    "translate(0px, -20px)",
  ],
  mobile: [
    "rotate(3deg)",
    "rotate(0deg)",
    "rotate(-5deg)",
  ],
};

const IntegrationCard = ({ icon, text, index, isIntersecting }) => {
  const [isHovered, setIsHovered] = useState(false);
  const cardRef = useRef(null);
  const isMobile = useMediaQuery("(max-width: 1200px)");

  useEffect(() => {
    const card = cardRef.current;

    if (!card) {
      return;
    }

    if (isMobile) {
      const baseTransform = interectionConfig.mobile[index];

      card.style.transform = baseTransform;
      } else {
      const baseTransform = interectionConfig.intersected[index];

      card.style.transform = isHovered
        ? baseTransform.replace(/rotate\([\d\-]+deg\)/, "rotate(0deg) scale(1.1)")
        : baseTransform;
    }
  }, [isHovered, index, isMobile]);

  return (
    <div
      className="integrations-card"
      ref={cardRef}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="integrations-card-bg integrations-card-icon shadow-7">
        <img src={icon} alt={`${text} icon`} className="icon-44" />
      </div>
      <div className="integrations-card-bg integrations-card-text shadow-7">
        {text}
      </div>
    </div>
  );
};

export const IntegrationCards = ({ isIntersecting }) => {
  const { t } = useTranslation();

  const cardData = useMemo(
    () => [
      { icon: googleMeets, text: t.integration1 },
      { icon: slackIcon, text: t.integration2 },
      { icon: linkedIn, text: t.integration3 },
    ],
    [t]
  );

  return (
    <div className="relative integrations-cards-list">
      <div className="blur-ball blur-size-326 blur-bg-5 blur-filter-90 integrations-cards-blur-ball"></div>
      {cardData.map((card, index) => (
        <IntegrationCard
          key={index}
          icon={card.icon}
          text={card.text}
          index={index}
          isIntersecting={isIntersecting}
        />
      ))}
    </div>
  );
};
