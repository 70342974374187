import { Fragment, useMemo, useRef } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Bolt } from "../assets/bolt.svg";
import { ReactComponent as Magnet } from "../assets/magnet.svg";
import { ReactComponent as UserGroup } from "../assets/user-group.svg";
import { ReactComponent as ChevronDown } from "../assets/chevron-down.svg";
import { ReactComponent as DocumentText } from "../assets/document-text.svg";

import { ReactComponent as SocialX } from "../assets/social-x.svg";
import { ReactComponent as SocialXing } from "../assets/social-xing.svg";
import { ReactComponent as SocialFacebook } from "../assets/social-facebook.svg";
import { ReactComponent as SocialLinkedin } from "../assets/social-linkedin.svg";
import { ReactComponent as SocialInstagram } from "../assets/social-instagram.svg";

import { paths } from "../constants/paths";
import { login, tryForFree } from "../utils/getPath";
import { useCollapse } from "../hooks/useCollapse";
import { useTranslation } from "../hooks/useTranslation";
import { LanguageButton } from "./LanguageButton";

const socials = [
  { icon: <SocialFacebook />, to: paths.facebook },
  { icon: <SocialXing />, to: paths.xing },
  { icon: <SocialLinkedin />, to: paths.linkedin },
  { icon: <SocialInstagram />, to: paths.instagram },
  { icon: <SocialX />, to: paths.x },
];

export const HeaderMenu = ({ open, toggleMenu }) => {
  const { t } = useTranslation();
  const { ref, collapsed, toggle } = useCollapse();
  const isExpanded = collapsed.value ? "active" : "";
  const dropdownRef = useRef(null);

  const products = useMemo(
    () => [
      {
        title: t.vacancies,
        icon: <DocumentText />,
        to: paths.vacancies,
      },
      {
        title: t.atsDatabase,
        icon: <UserGroup />,
        to: paths.atsDatabase,
      },
      {
        title: t.recruitingAsService,
        icon: <Magnet />,
        to: paths.recruitingAsService,
        extra: t.hot,
      },
      {
        title: t.hrPlatform,
        icon: <Bolt />,
        to: paths.hrPlatform,
      },
    ],
    [t]
  );

  return (
    <nav className={`menu-navigation ${open ? "expanded" : ""}`}>
      <div className="menu-navigation-list">
        <Link to={paths.home} onClick={toggleMenu}>
          <h4 className="menu-navigation-link">{t.home}</h4>
        </Link>
        <div className="horizontal-line"></div>

        <div>
          <div onClick={toggle} className={`menu-accordion ${isExpanded}`}>
            <h4 className={`menu-navigation-link ${isExpanded}`}>
              {t.product}
            </h4>

            <ChevronDown />
          </div>
          <div
            ref={ref}
            style={{ maxHeight: collapsed.height }}
            className={`faq-answer collapsed-content ml-8 ${isExpanded}`}
          >
            {products.map((product, index) => (
              <Fragment key={product.title || `product-header-${index}`}>
                {index === 0 && (
                  <p className="menu-product-title">
                    {t.recruiting}
                  </p>
                )}
                {index === 3 && (
                  <p className="body-medium text-secondary weight-600 mt-16 mb-4 ml-16">
                    {t.hrManagement}
                  </p>
                )}
                <Link to={product.to} onClick={toggleMenu}>
                  <div className="products-expanded-button">
                    <div className="products-expanded-button-icon text-primary">
                      {product.icon}
                    </div>
                    <p>{product.title}</p>
                    {product?.extra && (
                      <span className="products-expanded-extra">
                        {product.extra}
                      </span>
                    )}
                  </div>
                </Link>
              </Fragment>
            ))}
          </div>
        </div>

        <div className="horizontal-line"></div>
        <Link to={paths.prices} onClick={toggleMenu}>
          <h4 className="menu-navigation-link">{t.prices}</h4>
        </Link>
        <div className="horizontal-line"></div>
        <Link to={paths.contacts} onClick={toggleMenu}>
          <h4 className="menu-navigation-link">{t.contacts}</h4>
        </Link>

        <div className="menu-navigation-bottom">
          <LanguageButton ref={dropdownRef} className="shadow-8" />

          <div className="mt-60">
            <p className="body-medium weight-500 text-secondary">Follow us</p>
            <div className="flex gap-20 mt-20">
              {socials.map((item) => (
                <Link
                  key={item.to}
                  to={item.to}
                  target="_blank"
                  className="footer-social icon-32 text-blue-light-4"
                >
                  {item.icon}
                </Link>
              ))}
            </div>
          </div>
        </div>

        <div className="menu-buttons">
          <Link to={login}>
            <button className="button outlined-primary">{t.login}</button>
          </Link>
          <Link to={tryForFree("Company")}>
            <button className="button contained-primary">{t.tryForFree}</button>
          </Link>
        </div>
      </div>
    </nav>
  );
};
