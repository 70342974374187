import { forwardRef, useCallback } from "react";
import { Link } from "react-router-dom";

import checkbox from "../assets/checkbox.svg";
import xMarkIconBl from "../assets/x-mark-bl.svg";

import { Radio } from "./form/Radio";
import { FormCharity } from "./FormCharity";
import { FormStartup } from "./FormStartup";

import { paths } from "../constants/paths";
import { useTranslation } from "../hooks/useTranslation";
import { useValidateField } from "../hooks/useValidateField";
import { getRandomEmoji } from "../utils/getRandomEmoji";

const initialFormState = {
  formType: { value: "charity", error: "" },
  privacy: { value: false, error: "" },
  countryAndCityOfRegistration: { value: "", error: "" },
  nameOfTheFund: { value: "", error: "" },
  emailCharity: { value: "", error: "" },
  identificationCode: { value: "", error: "" },
  descriptionOfActivities: { value: "", error: "" },
  companyName: { value: "", error: "" },
  emailStartup: { value: "", error: "" },
  yearOfEstablishment: { value: "", error: "" },
  startupDescription: { value: "", error: "" },
};

const commonFields = ["privacy", "countryAndCityOfRegistration"];
const charityFields = [
  "nameOfTheFund",
  "emailCharity",
  "identificationCode",
  "descriptionOfActivities",
];
const startupFields = [
  "companyName",
  "emailStartup",
  "yearOfEstablishment",
  "startupDescription",
];
const formatDataAndCheckErrors = (formState) => {
  const fieldsToCheck =
    formState.formType.value === "charity"
      ? [...commonFields, ...charityFields]
      : [...commonFields, ...startupFields];

  const { hasErrors, formattedData } = fieldsToCheck.reduce(
    (acc, field) => {
      if (
        formState[field].value === "" ||
        (field === "privacy" && !formState[field].value)
      ) {
        acc.hasErrors = true;
      } else {
        acc.formattedData[field] = formState[field].value;
      }
      return acc;
    },
    { hasErrors: false, formattedData: { formType: formState.formType.value } }
  );

  if (hasErrors) {
    return { hasErrors, data: null };
  }

  const channel = "#site_inbox";
  const icon_emoji = getRandomEmoji();
  const username = `Form: Join for free (${formState.formType.value})`;
  const host = window.location.host || window.location.hostname;

  let text;

  if (formState.formType.value === "charity") {
    text = `Name of the fund: ${formattedData.nameOfTheFund}\nEmail: ${formattedData.emailCharity}\nIdentification code: ${formattedData.identificationCode}\nDescription: ${formattedData.descriptionOfActivities}\nCountry and City of Registration: ${formattedData.countryAndCityOfRegistration}\nHost: ${host}`;
  } else {
    text = `Company name: ${formattedData.companyName}\nEmail: ${formattedData.emailStartup}\nYear of establishment: ${formattedData.yearOfEstablishment}\nDescription: ${formattedData.startupDescription}\nCountry and City of Registration: ${formattedData.countryAndCityOfRegistration}\nHost: ${host}`;
  }

  return { hasErrors, data: { channel, icon_emoji, text, username } };
};

export const ModalJoinForFree = forwardRef(
  ({ formState, setFormState, open, toggleModal }, ref) => {
    const { t } = useTranslation();
    const { validateField } = useValidateField();

    const handleChange = useCallback((field, value) => {
      setFormState((prevState) => ({
        ...prevState,
        [field]: {
          value: value,
          error: validateField(field, value),
        },
      }));
    }, []);

    const handleFormTypeChange = useCallback(
      (event) => {
        setFormState(() => ({
          ...initialFormState,
          privacy: {
            value: false,
            error: "",
          },
          formType: {
            value: event.target.value,
            error: "",
          },
        }));
      },
      [initialFormState]
    );

    const handleSubmit = useCallback(
      async (e) => {
        e.preventDefault();

        const newFormState = { ...formState };

        let isValid = true;

        const fieldsToValidate = [
          ...commonFields,
          ...(formState.formType.value === "charity"
            ? charityFields
            : startupFields),
        ];

        fieldsToValidate.forEach((key) => {
          const error = validateField(key, newFormState[key].value);

          newFormState[key].error = error;

          if (error) {
            isValid = false;
          }
        });

        setFormState(newFormState);

        if (isValid) {
          try {
            const { hasErrors, data } = formatDataAndCheckErrors(formState);

            if (hasErrors) {
              const updatedFormState = { ...newFormState };

              fieldsToValidate.forEach((key) => {
                if (
                  updatedFormState[key].value === "" ||
                  (key === "privacy" && !updatedFormState[key].value)
                ) {
                  updatedFormState[key].error = "This field is required";
                }
              });

              setFormState(updatedFormState);

              return;
            }

            await fetch(
              "https://hooks.slack.com/services/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",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                mode: "no-cors",
                body: JSON.stringify(data),
              }
            );

            setFormState({
              ...initialFormState,
              formType: { formState: formState.formType.value, error: "" },
            });
          } catch (e) {
            console.log("Error", e);
          }
        } else {
          setFormState(newFormState);
        }
      },
      [formState, t]
    );

    return (
      <div className={`modal-bg ${open ? "expanded" : ""}`}>
        <div ref={ref} className="modal modal-prices prcs">
          <div className="flex items-center justify-between">
            <h4 className="text-secondary">{t.confirmationOfStatus}</h4>
            <button onClick={toggleModal} className="icon-button">
              <img src={xMarkIconBl} alt="close icon" className="icon-20" />
            </button>
          </div>
          <form noValidate className="mt-16" onSubmit={handleSubmit}>
            <div className="flex items-center">
              <Radio
                name="formType"
                value="charity"
                label={t.charityFund}
                checked={formState.formType.value === "charity"}
                onChange={handleFormTypeChange}
                labelClassName="mr-32"
              />
              <Radio
                name="formType"
                value="startup"
                label={t.startup}
                checked={formState.formType.value === "startup"}
                onChange={handleFormTypeChange}
                labelClassName="mr-32"
              />
            </div>

            {formState.formType.value === "charity" && (
              <FormCharity formData={formState} onChange={handleChange} />
            )}
            {formState.formType.value === "startup" && (
              <FormStartup formData={formState} onChange={handleChange} />
            )}

            <div className="mt-24">
              <label htmlFor="privacy" className="flex">
                <input
                  required
                  id="privacy"
                  name="privacy"
                  type="checkbox"
                  className="hidden"
                  checked={formState.privacy.value}
                  onChange={(e) => handleChange("privacy", e.target.checked)}
                />
                <div
                  className={`checkbox mt-4 ${
                    formState.privacy.value ? "checked" : ""
                  }`}
                >
                  <img src={checkbox} alt="checkbox icon" className="icon-12" />
                </div>

                <span className="privacy-link-text">
                  {t.agreePrivacyPolicy}{" "}
                  <Link to={paths.privacy} target="_blank" className="a underline">
                    {t.privacyPolicy}
                  </Link>{" "}
                  {t.and}{" "}
                  <Link to={paths.privacy} target="_blank" className="a underline">
                    {t.termsAndConditions}
                  </Link>
                </span>
              </label>
              {formState.privacy.error.length > 0 && (
                <p className="body-extra-small text-red mt-4">
                  {formState.privacy.error}
                </p>
              )}
            </div>

            <button
              type="submit"
              className="mt-24 button contained-primary w-full"
            >
              {t.sendLetter}
            </button>
          </form>
        </div>
      </div>
    );
  }
);
