import { useMemo } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Plus } from "../../assets/plus.svg";
import { ReactComponent as Clock } from "../../assets/clock.svg";
import { ReactComponent as Money } from "../../assets/money.svg";
import { ReactComponent as HomeModern } from "../../assets/home-modern.svg";
import { ReactComponent as AcademicCap } from "../../assets/academic-cap.svg";
import { ReactComponent as DocumentText } from "../../assets/document-text.svg";

import "./about-us.css";
import { tryForFree } from "../../utils/getPath";
import { useTranslation } from "../../hooks/useTranslation";
import { ReviewCard } from "../ReviewCard";
import { ContainedButtonWithIcon } from "../buttons/ContainedButtonWithIcon";

const icons = [
  <Money />,
  <Clock />,
  <Plus />,
  <DocumentText />,
  <AcademicCap />,
  <HomeModern />,
];

export const PageAboutUs = () => {
  const { t } = useTranslation();

  const list = useMemo(
    () => [
      {
        image: "avatar-hr-director.jpg",
        name: t.reviewHrDirectorName,
        position: t.hrDirector,
        email: t.reviewHrDirectorEmail,
        location: t.reviewHrDirectorLocation,
        quote: t.reviewQuote2,
      },
      {
        image: "avatar-project-manager.jpg",
        name: t.reviewProjectManagerName,
        position: t.projectManager,
        email: t.reviewProjectManagerEmail,
        location: t.reviewProjectManagerLocation,
        quote: t.reviewQuote3,
      },
    ],
    [t]
  );

  return (
    <div className="section-about-us">
      <div className="side-left">
        <h2 className="h2-about">{t.aboutUsTitle1}</h2>
        <p className="mt-12 body-medium text-secondary">{t.aboutUsSubtitle1}</p>
        <h2 className="h2-about">{t.aboutUsTitle2}</h2>
        <p className="mt-12 body-medium text-secondary">{t.aboutUsSubtitle2}</p>
        <p
          className="about-us-p"
          dangerouslySetInnerHTML={{ __html: t.aboutUsTitle3 }}
        ></p>
      </div>
      <div className="side-right">
        <div className="section section-reviews">
          <div className="section-bother-pulse rvws relative">
            <Link to={tryForFree("Company")} className="z-1">
              <ContainedButtonWithIcon title={t.tryForFree} />
            </Link>

            <div className="absolute absolute-center">
              {list.map((item, index) => (
                <ReviewCard
                  key={item.name || `review-about-${index}`}
                  item={item}
                  cardClass="about-us-review-card"
                />
              ))}

              {icons.map((icon, index) => (
                <div
                  key={`${icon}-${index}`}
                  className={`about-icon-container about-icon-${index + 1}`}
                >
                  {icon}
                </div>
              ))}

              <div className="pulse-container">
                <div className="pulse"></div>
                <div className="pulse"></div>
                <div className="pulse"></div>
                <div className="pulse"></div>
                <div className="pulse"></div>
              </div>

              <div className="blur-ball blur-size-400 blur-bg-6 blur-filter-90 absolute-center-top-left about-ball-1"></div>
              <div className="blur-ball blur-size-400 blur-bg-3 blur-filter-90 absolute-center-bottom-right about-ball-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
