import { useMemo } from "react";

import { useTranslation } from "../../hooks/useTranslation";

import "./products.css";

import { Reviews } from "../Reviews";
import { ProductHead } from "./ProductHead";
import { ProductSectionList } from "./ProductSectionList";

export const PageProductVacancies = () => {
  const { t } = useTranslation();

  const list = useMemo(
    () => [
      {
        stepTitle: `${t.step} 1`,
        title: t.vacanciesStep1Title,
        description: t.vacanciesStep1Description,
        image: "product-1-step-1.png",
      },
      {
        stepTitle: `${t.step} 2`,
        title: t.vacanciesStep2Title,
        description: t.vacanciesStep2Description,
        image: "product-1-step-2.png",
      },
      {
        stepTitle: `${t.step} 3`,
        title: t.vacanciesStep3Title,
        description: t.vacanciesStep3Description,
        image: "product-1-step-3.png",
      },
      {
        stepTitle: `${t.step} 4`,
        title: t.vacanciesStep4Title,
        description: t.vacanciesStep4Description,
        image: "product-1-step-4.png",
      },
      {
        stepTitle: `${t.step} 5`,
        title: t.vacanciesStep5Title,
        description: t.vacanciesStep5Description,
        image: "product-1-step-5.png",
      },
    ],
    [t]
  );

  const tags = useMemo(
    () => [t.product1tag1, t.product1tag2, t.product1tag3],
    [t]
  );

  return (
    <>
      <ProductHead
        title={t.productVacanciesTitle}
        subtitle={t.productVacanciesSubtitle}
        tags={tags}
        image={{
          src: "product-1.png",
          class: "max-w-810 mt-56",
          blurClass: "blur-size-600 blur-bg-3 blur-filter-90 top-right-2",
        }}
        activePath="vacancies"
      />

      <ProductSectionList list={list} />

      <Reviews />
    </>
  );
};
