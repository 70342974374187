import { useCallback, useState } from "react";

import xMarkIconBl from "../assets/x-mark-bl.svg";

import { Text } from "./form/Text";
import { TextArea } from "./form/Textarea";

import { getRandomEmoji } from "../utils/getRandomEmoji";
import { useTranslation } from "../hooks/useTranslation";
import { useValidateField } from "../hooks/useValidateField";
import { useOutsideClick } from "../hooks/useOutsideClick";

const initialFormState = {
  name: { value: "", error: "" },
  email: { value: "", error: "" },
  request: { value: "", error: "" },
};
const host = window.location.host || window.location.hostname;

export const ModalCreateRequest = ({ open, toggleModal }) => {
  const { t } = useTranslation();
  const { validateField } = useValidateField();
  const [formState, setFormState] = useState(initialFormState);

  const handleChange = useCallback((field, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: {
        value: value,
        error: validateField(field, value),
      },
    }));
  }, []);

  const resetForm = () => {
    setFormState(initialFormState);
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const newFormState = { ...formState };

      let isValid = true;

      Object.keys(newFormState).forEach((key) => {
        const error = validateField(key, newFormState[key].value);

        newFormState[key].error = error;

        if (error) {
          isValid = false;
        }
      });

      if (isValid) {
        try {
          const icon_emoji = getRandomEmoji();
          const formData = Object.keys(newFormState).reduce((acc, key) => {
            acc[key] = newFormState[key].value;

            return acc;
          }, {});
          const text = `Name: ${formData.name}\nHost: ${host}\nEmail: ${formData.email}\nRequest: ${formData.request}`;
          const username = "Form: Recruiting as service | Create reques modal";
          const data = {
            text,
            username,
            icon_emoji,
            channel: "#site_inbox",
          };

          await fetch(
            "https://hooks.slack.com/services/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",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              mode: "no-cors",
              body: JSON.stringify(data),
            }
          );

          resetForm();
        } catch (error) {
          setFormState(newFormState);
        }
      } else {
        setFormState(newFormState);
      }
    },
    [formState, t, host]
  );

  const { ref } = useOutsideClick(() => {
    if (open) {
      toggleModal();
      resetForm();
    }
  });

  const handleToggle = () => {
    toggleModal();
    resetForm();
  };

  return (
    <div className={`modal-bg ${open ? "expanded" : ""}`}>
      <div ref={ref} className="modal modal-prices">
        <div className="flex items-center justify-between">
          <h4 className="text-secondary">{t.createRequest}</h4>
          <button onClick={handleToggle} className="icon-button">
            <img src={xMarkIconBl} alt="close icon" className="icon-20" />
          </button>
        </div>
        <form noValidate onSubmit={handleSubmit} className="mt-28 h-full flex flex-col">
          <Text
            required
            type="text"
            name="name"
            label={t.nameLabel}
            placeholder={t.namePlaceholder}
            onChange={handleChange}
            value={formState.name.value}
            error={formState.name.error}
          />
          <Text
            required
            type="email"
            name="email"
            label={t.emailLabel}
            placeholder={t.emailPlaceholderEnter}
            containerClassName="mt-12"
            onChange={handleChange}
            value={formState.email.value}
            error={formState.email.error}
          />
          <TextArea
            required
            type="textarea"
            name="request"
            label={t.yourRequestLabel}
            placeholder={t.yourRequestPlaceholder}
            containerClassName="mt-12"
            onChange={handleChange}
            value={formState.request.value}
            error={formState.request.error}
          />
          <button
            type="submit"
            className="button contained-primary request-modal-button w-full"
          >
            {t.send}
          </button>
        </form>
      </div>
    </div>
  );
};
