import { useMemo } from "react";

import { useTranslation } from "../../hooks/useTranslation";

import "./products.css";

import { Reviews } from "../Reviews";
import { ProductHead } from "./ProductHead";

export const PageProductHRPlatform = () => {
  const { t } = useTranslation();

  const tags = useMemo(
    () => [t.product3tag1, t.product3tag2, t.product3tag3],
    [t]
  );

  return (
    <>
      <ProductHead
        title={t.productHRPlatformTitle}
        subtitle={t.productHRPlatformSubtitle}
        tags={tags}
        image={{
          src: "product-3.png",
          class: "max-w-1100 mt-56",
          blurClass: "blur-size-600 blur-bg-6 blur-filter-90 right-bottom z-1",
        }}
        activePath="hrPlatform"
      />

      <Reviews />
    </>
  );
};
