import mapPin from "../assets/map-pin.svg";

import { LocalizedImage } from "./LocalizedImage";

export const ReviewCard = ({ item, cardClass = "section-review-card" }) => {
  return (
    <div className={`${cardClass} shadow-7`}>
      <div className="relative">
        <div className="review-card-head">
          <LocalizedImage src={item.image} className="icon-44" />
          <div>
            <p className="body-medium text-secondary weight-500">{item.name}</p>
            <p className="body-small text-primary">{item.position}</p>
          </div>
        </div>
        <p className="mt-12 body-extra-small text-tertiary">{item.email}</p>
        <div className="review-card-location mt-12">
          <img src={mapPin} alt="pin icon" className="icon-16" />

          <p className="body-extra-small text-tertiary">{item.location}</p>
        </div>

        <div className="review-quote">{item.quote}</div>
      </div>
    </div>
  );
};
