import { Fragment, useMemo } from "react";

import { useTranslation } from "../hooks/useTranslation";

import { OrderedList } from "./ListOrdered";
import { UnorderedList } from "./ListUnordered";

export const PageLegalTermsOfService = () => {
  const { t } = useTranslation();

  const terms = useMemo(
    () => [
      {
        title: t.companyInfo,
        subtitle: t.agreementDetails,
      },
      {
        title: t.subscriptionScope,
        orderedList: {
          unorderedSublist: [
            { title: t.platformDescription },
            { title: t.subscriptionGrant },
            { title: t.subscriptionNature },
            { title: t.agreementAcceptance },
            { title: t.accountCreation },
          ],
        },
      },
      {
        title: t.platformGoals,
        subtitle: t.profileMatching,
        subtitle2: t.clientEntitlements,
        unorderedList: {
          sublist: [t.accessUserInterface, t.tailoredProfiles, t.analyzeJobs],
        },
      },
      {
        title: t.subscriptionPlansHeading,
        orderedList: {
          unorderedSublist: [
            {
              title: t.subscriptionPlanOptions,
              sublist: [
                t.freePlan,
                t.businessPlan,
                t.premiumPlan,
                t.usageBasedPlan,
              ],
            },
            { title: t.planConditions },
            { title: t.planModification },
          ],
        },
      },
      {
        title: t.supportHeading,
        orderedSublist: [t.technicalSupport, t.platformMaintenance],
        title2: t.supportDescription,
        orderedSublist2: [
          t.supportCoverage,
          t.incidentHandling,
          t.scheduledMaintenance,
          t.incidentReporting,
        ],
        title3: t.maintenanceDescription,
        orderedSublist3: [t.platformUpdates],
      },
      {
        title: t.confidentialityHeading,
        orderedList: {
          unorderedSublist: [
            {
              title: t.companyConfidentiality,
              sublist: [
                t.confidentialityMeasures,
                t.nonDisclosure,
                t.purposeLimitation,
                t.informationProtection,
              ],
            },
            { title: t.employeeConfidentiality },
            {
              title: t.confidentialityExceptions,
              sublist: [
                t.voluntaryDisclosure,
                t.preExistingInfo,
                t.publicInfo,
                t.thirdPartyInfo,
                t.legalDisclosure,
              ],
            },
            { title: t.confidentialityDuration },
          ],
        },
      },
      {
        title: t.intellectualProperty,
        orderedSublist: [t.ipOwnership, t.clientObligations, t.agreementBreach],
      },
      {
        title: t.dataProtection,
        orderedSublist: [
          t.gdprCompliance,
          t.clientDataEncounter,
          t.dataUse,
          t.dataSecurity,
          t.dataProcessingConsent,
        ],
      },
      {
        title: t.warrantiesLimitations,
        orderedSublist: [
          t.platformNoWarranty,
          t.liabilityExclusions,
          t.thirdPartyRelationships,
          t.forceMajeure,
        ],
      },
      {
        title: t.agreementTerm,
        orderedSublist: [
          t.clientTermination,
          t.companyTermination,
          t.breachTermination,
        ],
      },
      {
        title: t.generalDispositions,
        orderedSublist: [
          t.contractAssignment,
          t.credentialsNonTransferable,
          t.clauseSurvivability,
        ],
      },
      {
        title: t.applicableLaw,
        orderedSublist: [t.governingLaw, t.relevantLaws, t.disputeResolution],
      },
    ],
    [t]
  );

  return (
    <div className="section section-terms text-secondary">
      <h1 className="text-secondary text-uppercase">
        {t.marmendSubscription}:
      </h1>
      <h1 className="text-secondary text-uppercase weight-300">
        {t.termsAndConditionsForCompanies}
      </h1>

      <p className="mt-40 body-large text-primary weight-600">
        {t.versionDate}
      </p>

      <p className="mt-16 body-small max-w-700">{t.agreement}</p>

      <ol className="ol-terms">
        {terms.map((item, index) => (
          <li key={index}>
            <p className="body-large weight-600 text-uppercase mt-28">
              {item.title}
            </p>

            {item.subtitle && (
              <p
                className="body-small mt-16"
                dangerouslySetInnerHTML={{ __html: item.subtitle }}
              />
            )}

            {item.subtitle2 && (
              <p
                className="body-small mt-16"
                dangerouslySetInnerHTML={{ __html: item.subtitle2 }}
              />
            )}

            {item.orderedList?.unorderedSublist && (
              <ol className="ol-terms-sub mt-16">
                {item.orderedList.title && (
                  <p
                    className="body-small"
                    dangerouslySetInnerHTML={{ __html: item.orderedList.title }}
                  />
                )}

                {item.orderedList.unorderedSublist.map((listItem, subIndex) => (
                  <Fragment key={subIndex}>
                    <li
                      className="body-small mt-4"
                      dangerouslySetInnerHTML={{ __html: listItem.title }}
                    />
                    {listItem.sublist && (
                      <UnorderedList list={listItem.sublist} />
                    )}
                  </Fragment>
                ))}
              </ol>
            )}

            {item.unorderedList?.sublist && (
              <UnorderedList list={item.unorderedList.sublist} />
            )}

            {item.orderedSublist && (
              <OrderedList list={item.orderedSublist} className="mt-12" />
            )}

            {item.title2 && (
              <p className="body-small weight-600 mt-16">{item.title2}</p>
            )}
            {item.orderedSublist2 && (
              <OrderedList list={item.orderedSublist2} className="mt-12" />
            )}
            {item.title3 && (
              <p className="body-small weight-600 mt-16">{item.title3}</p>
            )}

            {item.orderedSublist3 && (
              <OrderedList list={item.orderedSublist3} className="mt-12" />
            )}
          </li>
        ))}
      </ol>
    </div>
  );
};
