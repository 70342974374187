import { Link } from "react-router-dom";
import { useCallback, useState, useEffect } from "react";

import "./cookie.css";
import { CookieButton } from "./CookieButton";
import { ModalSettings } from "./ModalSettings";

import { paths } from "../../constants/paths";
import { useTranslation } from "../../hooks/useTranslation";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { getStoredSettings } from "../../utils/getStoredSettings";
import { LOCAL_STORAGE_COOKIES } from "../../constants/cookiesLocalStorage";

const allSettings = {
  required: true,
  functional: true,
  analytics: true,
};

export const Cookie = ({ isModalOpen, setIsModalOpen }) => {
  const { t } = useTranslation();
  const [settings, setSettings] = useState(getStoredSettings);
  const [isCookieAccepted, setIsCookieAccepted] = useState(
    () => !!localStorage.getItem(LOCAL_STORAGE_COOKIES)
  );

  useEffect(() => {
    const storedSettings = localStorage.getItem(LOCAL_STORAGE_COOKIES);
    setIsCookieAccepted(!!storedSettings);
  }, []);

  const openModal = useCallback(() => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    document.body.style.overflow = "";
  }, []);

  const saveCookieSettings = useCallback((newSettings) => {
    localStorage.setItem(LOCAL_STORAGE_COOKIES, JSON.stringify(newSettings));
    setSettings(newSettings);
    setIsCookieAccepted(true);
  }, []);

  const onSubmit = useCallback(() => {
    saveCookieSettings(settings);
    closeModal();
  }, [settings, saveCookieSettings]);

  const acceptAll = useCallback(() => {
    saveCookieSettings(allSettings);
    closeModal();
  }, [saveCookieSettings]);

  const { ref } = useOutsideClick(() => {
    if (isModalOpen) {
      setIsModalOpen(false);
      document.body.style.overflow = "";
    }
  });

  return (
    <>
      <CookieButton onClick={openModal} />

      <ModalSettings
        ref={ref}
        open={isModalOpen}
        closeModal={closeModal}
        settings={settings}
        setSettings={setSettings}
        onSubmit={onSubmit}
        onSubmitAll={acceptAll}
      />

      {!isCookieAccepted && (
        <div className={`cookie-container ${!isCookieAccepted ? "z-1001" : ""}`}>
          <div className="section cookie-content">
            <div>
              <h4 className="text-secondary">{t.weUseCookies}</h4>
              <div className="mt-12 body-medium text-tertiary">
                {t.learnMoreInThe}{" "}
                <Link to={paths.privacy} className="a">
                  {t.privacyPolicy}
                </Link>{" "}
                {t.and}{" "}
                <Link to={paths.terms} className="a">
                  {t.termsOfUse}
                </Link>
              </div>
            </div>
            <div className="cookie-actions">
              <button className="button outlined-primary" onClick={openModal}>
                {t.settings}
              </button>
              <button
                onClick={onSubmit}
                className="button outlined-primary whitespace-nowrap ml-auto-xs-lg"
              >
                {t.onlyNecessaryCookies}
              </button>
              <button className="button contained-primary" onClick={acceptAll}>
                {t.accept}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
